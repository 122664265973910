import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import Countdown from 'react-countdown';
import Button from 'components/buttons/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		resend: 'Resend Code',
		submit: 'Submit',
		second: 'second',
		resendIn: 'Resend in'
	},
	id: {
		resend: 'Kirim Ulang',
		submit: 'Kirim',
		second: 'detik',
		resendIn: 'Kirim ulang dalam'
	}
};

const schema = yup.object().shape({
	otp: yup.string().required('fieldRequired').min(6, 'invalidLength')
});

const propTypes = {
	ready: PropTypes.bool.isRequired,
	setReady: PropTypes.func.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	languageCode: PropTypes.string.isRequired,
	otp: PropTypes.string.isRequired,
	setOtp: PropTypes.func.isRequired,
	verifyOtp: PropTypes.func.isRequired,
	otpVerify: PropTypes.object.isRequired,
	getOtp: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	resetOtpVerify: PropTypes.func.isRequired
};

const VerificationInput = props => {
	const {
		ready,
		setReady,
		languageCode,
		otp,
		setOtp,
		setIsOpen,
		verifyOtp,
		otpVerify,
		getOtp,
		auth,
		resetOtpVerify
	} = props;
	const translation = content[languageCode];
	const { token, email } = auth;
	const validationTranslation = FormValidationContent[languageCode];

	useEffect(() => {
		if (otpVerify.success) {
			resetOtpVerify();
			setIsOpen(true);
		};
	}, [otpVerify]);

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(schema)
	});

	const onSubmit = data => {
		verifyOtp(email, data.otp);
	};

	const resendToken = () => {
		getOtp(token);
		setReady(false);
	};

	const renderCountdown = ({ seconds }) => {
		return <span className="countdown">{translation.resendIn} {seconds} {translation.second}</span>;
	};

	return (
		<div className="verification-input">
			{ready && (
				<div className="resend" onClick={resendToken}>
					{translation.resend}
				</div>
			)}
			{!ready && (
				<Countdown
					className="countdown"
					date={Date.now() + 59000}
					onComplete={() => setReady(true)}
					renderer={renderCountdown}
				/>
			)}
			<div className="input-container">
				<ReactCodeInput className="input" onChange={e => setOtp(e)} />
				{(errors.otp || otpVerify.error) && (
					<div className="error-label">
						{validationTranslation[errors.otp?.message || 'invalidOtp']}
					</div>
				)}
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<input name="otp" ref={register} type="hidden" value={otp} />
				<Button className="submit-button" type="submit" variant="contained">
					{translation.submit}
				</Button>
			</form>
		</div>
	);
};

VerificationInput.propTypes = propTypes;

export default VerificationInput;
