import * as yup from 'yup';

const address = yup.object().shape({
	address: yup.string().required('fieldRequired').min(10, 'fieldTenCharacters').trim(),
	building_name: yup.string()
		.test('len', 'fieldTenCharacters', val => val.length ? val.length >= 10 : true).trim(),
	province_id: yup.string().required('fieldRequired'),
	city_id: yup.string().required('fieldRequired'),
	district_id: yup.string().required('fieldRequired'),
	postal_code: yup.string()
});

const property = yup.object().shape({
	certificate: yup.string().required('fieldRequired'),
	year_built: yup.string()
		.required('fieldRequired')
		.matches('^[0-9]+$', { message: 'fieldNumberOnly' })
		.test('len', 'invalidLength', val => val.length === 4)
});

const propertyDetails = yup.object().shape({
	land_size: yup.number().when('is_land_size_disabled', {
		is: false,
		then: yup.number()
			.typeError('fieldRequired')
			.required('fieldRequired')
			.min(1, 'fieldZero')
	}),
	building_size: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired')
		.min(1, 'fieldZero'),
	bedroom: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	bathroom: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	floor: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired')
		.min(1, 'fieldZero')
});

const parking = yup.object().shape({
	is_parking_availabe: yup.string().required('fieldRequired')
});

const referral = yup.object().shape({
	referral: yup.string().required(),
	answer: yup.string().when('referral', {
		is: 'Other',
		then: yup.string().required('fieldRequired').trim()
	})
});

const signup = yup.object().shape({
	email: yup.string().email('invalidEmailFormat').required('fieldRequired'),
	password: yup.string()
		.required('fieldRequired')
		.matches('^(?=.*[a-z])', 'fieldOneLowercase')
		.matches('^(?=.*[A-Z])', 'fieldOneUppercase')
		.matches('^(?=.*[0-9])', 'fieldOneNumeric')
		.min(8, 'filedEightCharacters')
		.max(16, 'fieldMaxSixteen')
});

const contact = yup.object().shape({
	fullname: yup.string().required('fieldRequired').trim(),
	phone: yup.string()
		.required('fieldRequired')
		.matches('^08', { message: 'phoneFormatInvalid' })
		.min(8, 'phoneFormatInvalid')
		.max(13, 'phoneFormatInvalid')
});

const income = yup.object().shape({
	income_sources: yup.string().required(),
	answer: yup.string().when('income_sources', {
		is: 'Other',
		then: yup.string().required('fieldRequired').trim()
	})
});

const monthly = yup.object().shape({
	monthly_payments: yup.string().required(),
	answer: yup.number().when('monthly_payments', {
		is: 'yes',
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	})
});

const reason = yup.object().shape({
	reason: yup.string().required('fieldRequired').trim()
});

const cash = yup.object().shape({
	cash: yup.number().min(1).required('fieldRequired')
});

const financial = yup.object().shape({
	cash: yup.number().min(1, 'fieldNoCash').required('fieldRequired'),
	pension: yup.number().when('is_pension_checked', {
		is: true,
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	}),
	investment: yup.number().when('is_investment_checked', {
		is: true,
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	}),
	business_account: yup.number().when('is_business_account_checked', {
		is: true,
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	}),
	other: yup.number().when('is_other_checked', {
		is: true,
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	}),
	answer: yup.string().when('is_other_checked', {
		is: true,
		then: yup.string().required('fieldRequired').trim()
	})
});

const timeline = yup.object().shape({
	timeline: yup.string().required('fieldRequired')
});

const estimate = yup.object().shape({
	estimate: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
});

const mortgage = yup.object().shape({
	mortgage: yup.string().required(),
	month: yup.string().when('mortgage', {
		is: 'yes',
		then: yup.string().required('fieldRequiredShort')
	}),
	year: yup.string().when('mortgage', {
		is: 'yes',
		then: yup.string().required('fieldRequiredShort')
	})
});

const result = yup.object().shape({
	result: yup.string().required('fieldRequired')
});

const resultReason = yup.object().shape({
	reason: yup.string().required(),
	answer: yup.string().when('reason', {
		is: 'Other',
		then: yup.string().required('fieldRequired').trim()
	})
});

const listing = yup.object().shape({
	listing: yup.string().required(),
	answer: yup.number().when('listing', {
		is: 'yes',
		then: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
	})
});

const purchase = yup.object().shape({
	month: yup.string().required('fieldRequiredShort'),
	year: yup.string().required('fieldRequiredShort')
});

const price = yup.object().shape({
	price: yup.number().min(1, 'fieldNoCash').required('fieldRequired')
});

const buyback = yup.object().shape({
	buyback: yup.string().required('fieldRequired').trim()
});

const process = yup.object().shape({
	process: yup.string().required()
});

const looking = yup.object().shape({
	looking: yup.string().required()
});

const lookingTimeline = yup.object().shape({
	looking_timeline: yup.string().required()
});

const lookingLocation = yup.object().shape({
	looking_location: yup.string().required('fieldRequired').trim()
});

const propertyType = yup.object().shape({
	property_type: yup.string().required()
});

const propertyComplete = yup.object().shape({
	is_property_complete: yup.string().required()
});

const marital = yup.object().shape({
	marital_status: yup.string().required(),
	children: yup.string().when('marital_status', {
		is: 'Single',
		otherwise: yup.string().required('fieldRequired')
	})
});

const employment = yup.object().shape({
	employment_status: yup.string().required()
});

const employmentDetails = yup.object().shape({
	company_name: yup.string().required('fieldRequired').trim(),
	company_address: yup.string().required('fieldRequired').trim(),
	company_phone: yup.string()
		.required('fieldRequired')
		.matches('^0', { message: 'phoneFormatInvalid' })
		.min(8, 'phoneFormatInvalid')
		.max(13, 'phoneFormatInvalid'),
	years: yup.string()
		.required('fieldRequired')
});

const monthlyIncome = yup.object().shape({
	monthly_income: yup.number().min(1).required('fieldRequired')
});

const spend = yup.object().shape({
	spend: yup.number().min(1).required('fieldRequired')
});

const downPayment = yup.object().shape({
	downpayment: yup.number().min(5000000).required('fieldRequired')
});

const monthlyPayment = yup.object().shape({
	monthly_payment: yup.number().min(1200000).required('fieldRequired')
});

const currentLiving = yup.object().shape({
	current_address: yup.string().required('fieldRequired').trim(),
	current_property: yup.string().required('fieldRequired'),
	property_status: yup.string().when('current_property', {
		is: 'kos',
		otherwise: yup.string().required('fieldRequired')
	})
});

const propertyDescribe = yup.object().shape({
	land_size: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	building_size: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	beds: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	baths: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired'),
	floor: yup.number()
		.typeError('fieldRequired')
		.required('fieldRequired')
		.min(1, 'fieldZero'),
	certificate: yup.string().required('fieldRequired')
});

const parkingDescribe = yup.object().shape({
	parking: yup.string().required(),
	answer: yup.string().when('parking', {
		is: 'yes',
		then: yup.string().required('fieldRequired')
	})
});

export default {
	address,
	property,
	propertyDetails,
	parking,
	referral,
	signup,
	contact,
	income,
	monthly,
	reason,
	cash,
	financial,
	timeline,
	estimate,
	mortgage,
	result,
	resultReason,
	listing,
	purchase,
	price,
	buyback,
	process,
	looking,
	lookingTimeline,
	lookingLocation,
	propertyType,
	propertyComplete,
	marital,
	employment,
	employmentDetails,
	monthlyIncome,
	spend,
	downPayment,
	monthlyPayment,
	currentLiving,
	propertyDescribe,
	parkingDescribe
};
