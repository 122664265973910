export default {
	en: {
		fieldNumberOnly: 'This field must contain only numbers.',
		fieldNumberOnlyShort: 'Numbers only.',
		fieldRequired: 'This field is required.',
		fieldRequiredShort: 'Required.',
		fieldNoCash: `This field couldn’t be Rp0,-.`,
		fieldZero: `This field couldn’t be 0.`,
		fieldOneLowercase: 'Must contain at least 1 lowercase character.',
		fieldOneUppercase: 'Must contain at least 1 uppercase character.',
		fieldOneNumeric: 'Must contain at least 1 numeric character.',
		fieldTenCharacters: 'Must be 10 characters or longer.',
		fieldMaxSixteen: 'Must be 16 characters or shorter.',
		fieldMaxThirteen: 'Must be 13 characters or shorter.',
		invalidEmailFormat: 'Invalid email.',
		minFiveLettersShort: 'Min. 5 letters.',
		needFile: 'You need to provide a file.',
		phoneFormatInvalid: 'Invalid phone number.',
		invalidLength: 'Input length is invalid.',
		invalidOtp: 'Kode OTP tidak sesuai. Silahkan coba lagi.'
	},
	id: {
		fieldNumberOnly: 'Gunakan angka saja.',
		fieldNumberOnlyShort: 'Angka saja.',
		fieldRequired: 'Bagian ini diperlukan.',
		fieldRequiredShort: 'Wajib diisi.',
		fieldNoCash: `Tidak boleh Rp0,-.`,
		fieldZero: `Tidak boleh 0.`,
		fieldOneLowercase: 'Harus berisi setidaknya 1 karakter huruf kecil.',
		fieldOneUppercase: 'Harus berisi setidaknya 1 karakter huruf kapital.',
		fieldOneNumeric: 'Harus berisi setidaknya 1 karakter angka.',
		fieldTenCharacters: 'Harus 10 karakter atau lebih.',
		fieldMaxSixteen: 'Harus 16 karakter atau kurang.',
		fieldMaxThirteen: 'Harus 13 karakter atau kurang.',
		invalidEmailFormat: 'Email tidak sah.',
		minFiveLettersShort: 'Min. 5 huruf.',
		needFile: 'Anda perlu menyertakan berkas.',
		phoneFormatInvalid: 'Nomor telpon tidak sah.',
		invalidLength: 'Panjang input tidak sah.',
		invalidOtp: 'Kode OTP tidak sesuai. Silahkan coba lagi.'
	}
};
